import React, { useContext, useState } from "react"
import { graphql } from "gatsby"
import { FormattedMessage, useIntl } from "react-intl"
import Sticky from "react-stickynode"
import classNames from "classnames"

import Carousel from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"

import Layout from "../layouts"
import { stickyTop } from "../config/main"

import { goToSlideByNumber, getCarouselPlugins } from "../utils/carousel-utils"
import { deliveryFreeCartTotal, getCartTotal } from "../utils/cart-properties"
import { getShopifyContentfulMatcher } from "../utils/product-matcher"
import { PageContext } from "../utils/wrap-page-element"
import { StoreContext } from "../utils/store-context"
import useWindowWidth from "../utils/window-width"

import CartLineItem from "../components/CartLineItem"
import DeliveryProviderIcons from "../components/DeliveryProviderIcons"
import LocalizedLink from "../components/LocalizedLink"
import PaymentMethodIcons from "../components/PaymentMethodIcons"
import ProductBox from "../components/ProductBox"
import TotalCosts from "../components/TotalCosts"

const CartPage = props => {
  // props
  const {
    data: {
      navMenus: menus,
      allShopifyProductVariant: { nodes: shopifyProductVariants },
      allContentfulProduct: { nodes: contentfulProductVariants },
    },
    // pageContext: { productVariantIds, productVariantIdsBase64 }
  } = props

  // hooks
  const { formatMessage, formatNumber } = useIntl()
  const {
    initialLoading,
    loading,
    checkout,
    products,
    getLocalizedProduct,
    removeLineItem,
    updateLineItem,
  } = useContext(StoreContext)
  const { locale } = useContext(PageContext)
  const cartItems = checkout?.lineItems || []

  // get total of all line items in cart
  const cartTotal = getCartTotal(cartItems)

  const handleQuantityAddClick = (_e, lineItem) => {
    updateLineItem(checkout.id, lineItem.id, lineItem.quantity + 1)
  }
  const handleQuantityRemoveClick = (_e, lineItem) => {
    updateLineItem(checkout.id, lineItem.id, lineItem.quantity - 1)
  }
  const handleQuantityRemoveAllClick = (_e, lineItem) => {
    removeLineItem(checkout.id, lineItem.id)
  }

  let checkoutUrl = "#"
  if (checkout && checkout.webUrl) {
    checkoutUrl = checkout?.webUrl.replace("m99io.myshopify.com", "shop.woodist.de")
  }

  const productImageColumnSize = 100
  const productQuantityColumnSize = 260
  const numberOfItemsShown = 10

  const [isLoading, setIsLoading] = useState(false)

  const buttonClasses = classNames({
    button: true,
    "is-primary": true,
    "is-fullwidth": true,
    "is-loading": isLoading,
  })

  // extract localized menu collections
  const localizedMenuCollections = Object.values(menus.nodes)
    .flat()
    .filter(menu => menu.node_locale === locale)
    .flat()
    .map(menu => menu.menuEntries)
    .flat()
    .filter(entry => typeof entry.slug !== "undefined")

  /**
   * get related product meta data if available
   * @param {Array} contentfulProducts
   * @param {Object} product
   * @param {String} locale
   * @returns
   */
  const getRelatedProductMetaData = (contentfulProducts, product, locale) => {
    return contentfulProducts.find(c => {
      return (
        product.variants.some(
          v => getShopifyContentfulMatcher(v, c)
        ) && c.node_locale === locale
      )
    })
  }

  // carousel (single)
  const [currentSlides, setCurrentSlides] = useState([0])

  const width = useWindowWidth()
  let numberOfSlidesShown = 5
  let stickyEnabled = false
  if (width <= 1216) {
    numberOfSlidesShown = 4
    stickyEnabled = true
  }
  if (width <= 1024) {
    numberOfSlidesShown = 3
    stickyEnabled = true
  }
  if (width <= 768) {
    numberOfSlidesShown = 2
    stickyEnabled = false
  }
  if (width <= 480) {
    numberOfSlidesShown = 1
    stickyEnabled = false
  }

  const limitedShopifyProductVariants =
    [...shopifyProductVariants].slice(0, numberOfItemsShown)

  const bestSellerBoxes = limitedShopifyProductVariants.map((productVariant, index) => (
    <ProductBox
      area={productVariant.product.id}
      key={productVariant.product.id}
      product={{
        ...productVariant.product,
        handle:
          getRelatedProductMetaData(
            contentfulProductVariants,
            productVariant.product,
            locale
          )?.productSlug || productVariant.product.handle,
        title:
          getRelatedProductMetaData(
            contentfulProductVariants,
            productVariant.product,
            locale
          )?.productTitle || productVariant.product.title,
      }}
      locale={locale}
      onPaper={true}
      lazyLoading={index >= numberOfSlidesShown}
    />
  ))

  // determine if there are only gift cards in the cart
  const onlyGiftCards = products.every(product => product.isGiftCard === true)

  return (
    <Layout title={formatMessage({ id: "pages.cart.title" })}>
      <section className="section has-background-paper">
        <div className="container">
          {cartItems.length > 0 && (<h1 className="title mt-6">
            <FormattedMessage id="pages.cart.title" />
          </h1>)}
          {cartItems.length === 0 && (<h1 className="title mt-6">
            <FormattedMessage id="pages.cart.title-empty" />
          </h1>)}
        </div>
      </section>

      {/* Loading */}
      {initialLoading && (
        <section className="section">
          <div className="container">
            <FormattedMessage id={"general.loading"} />
          </div>
        </section>
      )}

      {/* Empty cart */}
      {!initialLoading && cartItems.length === 0 && (
        <section className="section">
          <div className="container">
            <h2 className="subtitle">
              <FormattedMessage id={"cart.empty-note"} />
            </h2>
            {typeof window !== "undefined" && Carousel && <Carousel
              plugins={getCarouselPlugins(
                currentSlides,
                setCurrentSlides,
                0,
                limitedShopifyProductVariants.length,
                numberOfSlidesShown,
                formatMessage({ id: "general.previous" }),
                formatMessage({ id: "general.next" })
              )}
              value={currentSlides[0]}
              onChange={index =>
                goToSlideByNumber(
                  currentSlides,
                  setCurrentSlides,
                  0,
                  limitedShopifyProductVariants.length,
                  numberOfSlidesShown,
                  index
                )
              }
            >
              {bestSellerBoxes}
            </Carousel>}
          </div>
        </section>
      )}

      {/* Non-empty cart */}
      {!initialLoading && cartItems.length > 0 && (
        <section className="section" id="content">
          <div className="container">
            <div className="columns is-multiline">
              <div className="column is-12-touch">

                {/* Delivery cost note */}
                {cartTotal < deliveryFreeCartTotal && !onlyGiftCards && (
                  <div className="notification is-light py-2">
                    <FormattedMessage id={"cart.delivery-free-note"} values={{
                      amount: formatNumber(deliveryFreeCartTotal - cartTotal, {
                        style: "currency",
                        currency: "EUR",
                      })
                    }} />
                  </div>
                )}
                {cartTotal >= deliveryFreeCartTotal && !onlyGiftCards && (
                  <div className="notification is-light py-2">
                    <FormattedMessage id={"cart.delivery-free-note-success"} />
                  </div>
                )}

                {/* Cart items */}
                <div className="columns is-multiline">
                  <div className="column is-12 is-hidden-mobile">
                    <div className="columns has-border-bottom">
                      <div
                        className="column is-narrow"
                        style={{ width: productImageColumnSize }}
                      ></div>
                      <div className="column">
                        <FormattedMessage id={"general.product"} />
                      </div>
                      <div
                        className="column is-narrow"
                        style={{ width: productQuantityColumnSize }}
                      >
                        <FormattedMessage id={"general.quantity"} />
                      </div>
                    </div>
                  </div>
                  {cartItems.map(lineItem => {
                    const product = getLocalizedProduct(
                      products,
                      lineItem.variant.product.id,
                      locale
                    )
                    return (
                      <CartLineItem
                        area={lineItem.variant.id}
                        key={lineItem.id}
                        product={product}
                        lineItem={lineItem}
                        handleQuantityAddClick={handleQuantityAddClick}
                        handleQuantityRemoveClick={handleQuantityRemoveClick}
                        handleQuantityRemoveAllClick={
                          handleQuantityRemoveAllClick
                        }
                        productImageColumnSize={productImageColumnSize}
                        productQuantityColumnSize={productQuantityColumnSize}
                        loading={loading}
                      />
                    )
                  })}
                </div>

                <div className="is-hidden-mobile is-hidden-tablet-only">
                  {/* Payment methods */}
                  <p className="mt-6 mb-2"><FormattedMessage id={"cart.payment-methods"} /></p>
                  <p className="mt-0"><PaymentMethodIcons big={true} /></p>

                  {/* Delivery providers */}
                  <p className="mt-0 mb-2"><FormattedMessage id={"cart.delivery-providers"} /></p>
                  <p className="mt-0"><DeliveryProviderIcons big={true} /></p>

                  {/* Tax note */}
                  <small>
                    <FormattedMessage id={"general.tax-note"} />
                  </small>
                </div>
              </div>
              <div className="column is-3-desktop is-offset-1-desktop is-12-touch">
                <Sticky top={stickyTop} bottomBoundary="#content" enabled={stickyEnabled}>
                  <div className="box">
                    <div className="columns is-multiline">
                      <div className="column is-12">
                        <TotalCosts lineItems={cartItems} products={products} />
                      </div>
                      <div className="column is-12 has-border-top-light">
                        <FormattedMessage id={"cart.coupon-hint"} />
                      </div>
                      <div className="column is-12 has-border-top-light">
                        <button
                          className={buttonClasses}
                          disabled={isLoading}
                          onClick={() => {
                            if (window) {
                              setIsLoading(true)
                              window.location.href = checkoutUrl
                            }
                          }}
                        >
                          <FormattedMessage id={"cart.go-to-checkout"} />
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* Continue shopping */}
                  <p className="mt-6">
                    <LocalizedLink
                      key={localizedMenuCollections[0].slug}
                      to={`/${localizedMenuCollections[0].slug}`}
                      className="button is-fullwidth"
                    >
                      <FormattedMessage id={"cart.continue-shopping"} />
                    </LocalizedLink>
                  </p>
                </Sticky>

                <div className="is-hidden-desktop">
                  {/* Payment methods */}
                  <p className="mt-6 mb-2"><FormattedMessage id={"cart.payment-methods"} /></p>
                  <p className="mt-0"><PaymentMethodIcons big={true} /></p>

                  {/* Delivery providers */}
                  <p className="mt-0 mb-2"><FormattedMessage id={"cart.delivery-providers"} /></p>
                  <p className="mt-0"><DeliveryProviderIcons big={true} /></p>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* <pre>{JSON.stringify(productVariantIds, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(productVariantIdsBase64, null, 2)}</pre> */}
    </Layout>
  )
}

export default CartPage

export const CartPageQuery = graphql`
  query ($productVariantIds: [String!]!, $productVariantIdsBase64: [String!]!) {
    navMenus: allContentfulMenu(
      filter: { menuSlug: { eq: "main-navigation" } }
    ) {
      nodes {
        node_locale
        menuSlug
        menuEntries {
          ... on ContentfulCollection {
            id
            internal {
              type
            }
            slug: collectionSlug
            title: collectionTitle
            contentful_id
            shopifyCollectionStorefrontId
          }
        }
      }
    }
    shopifyCollection(handle: { eq: "best-seller" }) {
      description
      descriptionHtml
      id
      title
      products {
        description
        descriptionHtml
        handle
        hasOnlyDefaultVariant
        hasOutOfStockVariants
        id
        images {
          altText
          id
          gatsbyImageData(
            layout: CONSTRAINED
            width: 400
            aspectRatio: 0.75
            breakpoints: [200, 300, 400]
          )
        }
        isGiftCard
        priceRangeV2 {
          maxVariantPrice {
            amount
            currencyCode
          }
          minVariantPrice {
            amount
            currencyCode
          }
        }
        productType
        shopifyId
        status
        storefrontId
        tags
        title
        totalVariants
        variants {
          availableForSale
          compareAtPrice
          id
          price
          shopifyId
          storefrontId
          title
        }
      }
    }
    allShopifyProductVariant(
      filter: { storefrontId: { in: $productVariantIds } }
    ) {
      nodes {
        storefrontId
        product {
          collections {
            id
            handle
            title
          }
          description
          descriptionHtml
          handle
          hasOnlyDefaultVariant
          hasOutOfStockVariants
          id
          images {
            altText
            id
            gatsbyImageData(
              layout: CONSTRAINED
              width: 400
              aspectRatio: 0.75
              breakpoints: [200, 300, 400]
            )
          }
          isGiftCard
          options {
            id
            name
            values
          }
          priceRangeV2 {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          productType
          storefrontId
          title
          totalVariants
          variants {
            availableForSale
            compareAtPrice
            id
            price
            storefrontId
            title
          }
        }
      }
    }
    allContentfulProduct(
      filter: { shopifyProductVariantId: { in: $productVariantIdsBase64 } }
    ) {
      nodes {
        node_locale
        productSlug
        productTitle
        shopifyProductVariantId
      }
    }
  }
`
