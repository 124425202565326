import React from "react"

import dhl from "../../static/images/dhl.svg"

const DeliveryProviderIcons = ({ big = false }) => {
  const bigModifier = big ? " payment-method-icon-big" : ""
  return (
    <React.Fragment>
      <img src={dhl} className={`ml-2 delivery-provider-icon${bigModifier}`} alt="DHL" title="DHL" />
    </React.Fragment>
  )
}

export default DeliveryProviderIcons
